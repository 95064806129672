export class De {
  welcomeMessage = "Willkommen in";
  continue = "Weiter";
  cancel = "Abbrechen";
  confirm = "Bestätigen";
  proceed = "Fortfahren";
  retry = "Wiederholen";
  search = "Suche";
  nothing = "Nichts";
  userHint1 = "Geben Sie den Code ein";
  insertPoDCode = "Pod Code";
  recharge = "Neu laden";
  meters = "meters";
  km = "km";
  currency = {
    eur: "€",
    usd: "$",
  };
  emailPlaceholder = "Ihre @email";
  box = "Kasten";
  locker = "Sperren";
  boxSelectionTitle = "Kasten";
  boxSelectionHint = "Wählen Kasten";
  toolsSelectionTitle = "Werkzeuge";
  toolsSelectionHint = "Wählen Werkzeuge";
  sendToolsActivationCode = "senden code";
  lockerSelectionTitle = "Padlock des Bahnhofs";
  lockerSelectionHint = "Wählen padlock";
  sendBoxPin = "Send box code";
  sendLockerPin = "Vorhängeschloss-Code senden";
  boxPinSentMessage = "Sie erhalten eine Mail mit dem Code zum Öffnen der Box";
  lockerPinSentMessage =
    "You'll receive a mail with the code for opening the padlock";
  socketPinSentMessage =
    "Sie erhalten eine Mail mit dem Code zum Öffnen des Vorhängeschlosses";
  thankYouMessage1 = "Danke. Auf Wiedersehen!";
  somethingWentWrong = "OOPS Etwas ist schief gelaufen:(";
  genericError = "Error";
  operationSuccessToolsBox = "Operation complete";
  warningStationOffline = "WARNUNG: Der von Ihnen gewählte Sender ist OFFLINE";
  completeCheckoutWithPin = "Nimm deine Sachen";
  howToCompleteCheckout =
    "Um Ihre Artikel abzuholen, lesen Sie den QR-Code erneut und folgen Sie dem Assistenten.";
  otherwise = "Andernfalls";
  goBack = "geh zurück";
  chargingPoint = "Charging Point";
  chooseTheSocket = "Choose the socket";
  pinCodeHelperMessage = "Is this your pin?";
  insertYourPinCode = "Insert the PIN";
  yourPinCode = "Your PIN";
  insertYourEmail = "Insert Email";
  areYouThereOrHere = "You are not here?";
  emailNotValid = "Mail incorrect";
  confirmations = {
    conf1: "Sie haben die Ladestation ausgewählt",
    conf2: "Bestätigen, um fortzufahren",
    conf3: "Sie haben die Ladestation ausgewählt",
    conf4: "und die Steckdose",
  };
  insertRechargeTime = "Charging Time";
  minutes = "Protokoll";
  congratulationsBoxOpened = "Die Kiste ist offen";
  congratulationsActivationCompleted = "Gut erledigt! Aktivierung erfolgreich";
  ifBoxNotOpened =
    "Das Podlock oder die Box ist nicht geöffnet? Versuchen Sie es erneut und ziehen Sie innerhalb von 6 Sekunden an der Kette";
  ifSocketNotActivated = "Socket not activated?Try again";
  howToCompleteCheckout2 =
    "Warning:Reaching the time the charging will be stopped";
  additionalInfo_title = "Additional station information";
  additionalInfo_ua = "UA";
  additionalInfo_prt = "PRT";
  choosePaymentOption = "Choose the amount";
  preconfiguredOption_1 = "1h = 4 €";
  preconfiguredOption_2 = "2h = 5,50 €";
  preconfiguredOption_3 = "4h = 8,50 €";
  preconfiguredOption_4 = "6h = 10,50 €";
  preconfiguredOption_5 = "10h = 13,50 €";
  preconfiguredOption_6 = "12h = 15,00 € ";
  preconfiguredOption_1_3 = "1h = 1,50 € ";
  preconfiguredOption_3_1 = "30 min = 1,00 €";
  preconfiguredOption_3_2 = "60 min = 1,50 €";
  preconfiguredOption_3_3 = "90 min = 2,00 €";
  preconfiguredOption_3_4 = "120 min = 2,50 €";
  preconfiguredOption_5_1 = "30 min = 0,50 €";
  preconfiguredOption_5_2 = "60 min = 1,00 €";
  preconfiguredOption_5_3 = "90 min = 1,50 €";
  preconfiguredOption_5_4 = "120 min = 2,00 €";
  preconfiguredOption_6_1 = "1h = 1,00 €";
  paymentOkRechargeStarted =
    "Stecken Sie die Steckdose ein, Ihre Aufladung beginnt bald.";
  paymentOkEmailPINSentLocker =
    "Sie erhalten eine E-Mail mit der PIN zum Öffnen des Podlocks";
  paymentOkEmailPINSentBox =
    "Sie erhalten eine E-Mail mit der PIN zum Öffnen der Box";
  paymentOkEmailPINSentSocket =
    "Sie erhalten eine E-Mail mit der PIN, um die Aufladung zu aktivieren";
  doUWantARecharge = "Do you want to charge?";
  station = "Station";
  qrCodeScan1 = "Nähern Sie sich der Ladestation und finden Sie den QR-Code";
  qrCodeScan2 = "QR-Code scannen";
  qrCodeScanSuggestion1 = "Read the QR Code";
  qrCodeScanNotAvailable = "QR-Code des Scanners nicht verfügbar";
  cardOwner = "Name";
  email = "E-Mail";
  pay = "Bezahlen";
  processInProgress = "in Bearbeitung...";
  serviceCheckedOut = "Beschäftigt";
  notAvailable = "Nicht verfügbar";
  openLocker = "Offenes Vorhängeschloss";
  insert4DigiCode = "4-stelligen Code eingeben";
  invalidCode = "Ungültiger Code";
}
